<div id="dashboard">
    <div id="main-content">

        <div id="signed-out-content-container" *ngIf="!userIsAuthenticated && userAuthenticationCheckPerformed">
            <div *ngIf="applicationName == 'Visible Speech'" id="about-container">
                <h2>About</h2>
                Visible Speech is a collection of research tools for speech analysis.
                If you wish to have access to the system, please get in touch with us at <a href="mailto:support@humlab.umu.se">support@humlab.umu.se</a>.<br /><br />Visible Speech is being developed by Umeå university.
            </div>
            <div *ngIf="applicationName == 'DataLab'" id="about-container">
                <h2>About</h2>
                Datalab allows you as a researcher to store your data in a version controlled manner and work with that data using online computational tools.
                <br /><br />Datalab is being developed by Humlab at Umeå university.
            </div>
            <app-signin-ctrl></app-signin-ctrl>
        </div>
        
        <app-project-manager *ngIf="userAuthenticationCheckPerformed && userIsAuthenticated && userIsAuthorized" [dashboard]="this"></app-project-manager>

        <app-invite-codes-dialog *ngIf="(modalActive && modalName=='invite-codes-dialog')"></app-invite-codes-dialog>
        
        <div id="userFailedAccessListCheckContainer" *ngIf="userAuthenticationCheckPerformed && userIsAuthenticated && !userIsAuthorized">
            
            <h2>Missing access grant</h2>

            <p>You have successfully authenticated yourself as {{ userFullName }}, but you still lack the authorization to use this system.
            This is normally provided via invite codes, if you have such a code, please enter it below.</p>
            <app-invite-code-entry></app-invite-code-entry>
            <p>If you do not have an invite code and you would like to be granted access, please get in touch with us at <a href="mailto:support@humlab.umu.se">support@humlab.umu.se</a>.</p>
        </div>

    </div>
    
    <div id="sidebar">
        <app-infobox-manager></app-infobox-manager>
    </div>

</div>